import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSession } from "next-auth/react";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/router";
import ClinicNotification from "@components/clinic/ClinicNotification";
import { useSelector } from "react-redux";
import CustomSelect from "@components/unit/CustomSelect";
import { useDispatch } from "react-redux";
import { clinicAction } from "@store/clinic/clinicSlice";
import { useEffect } from "react";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { snackbarAction } from "@store/snackbar/snackbarSlice";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;
  const { data: session, update } = useSession();
  const router = useRouter();
  const dispatch = useDispatch();
  const storeInfo = session.user.storeInfo;
  const admin_current_store_id = useSelector((state) => state.clinic.admin_current_store_id);

  const pathCode = {
    "/": "대시보드",
    "/challenge": "챌린지",
    "/packageChallenge": "챌린지시리즈 관리",
    "/answerV2": "1분챌린지 심사",
    "/keyword": "키워드 관리",
    "/csv/section": "섹션",
    "/csv/form": "설문",
    "/csm/formResp": "설문 응답",
    "/csm/clinic": "클리닉 전체 현황",
    "/userPet": "반려동물",
    "/petKind": "반려동물 품종",
    "/play": "플레이 사용현황",
    "/unit": "플레이 유닛",
    "/answerV2/event": "망한 반려동물 사진 자랑대회",
    "/knolloStore": "놀로 스토어",
    "/event": "이벤트",
    "/point/total": "포인트 전체 현황",
    "/point/user": "사용자 포인트 현황",
    "/point/coupon": " 리워드 관리",
    "/order": "주문 관리",
    "/salescheck": "매출 조회",
    "/scm/inventory": "재고 관리",
    "/scm/expired": "유통기한 관리",
    "/scm/prepareshipping": "배송 준비",
    "/admin": "운영자 관리",
    "/commonCode": "공통코드 관리",
    "/attachment": "스토리지 관리",
    "/clinic/dashboard": "클리닉 대시보드",
    "/clinic/itemlist": "품목 설정",
    "/clinic/products": "품목 추가",
    "/clinic/itemtimeline": "판매시간 설정",
    "/clinic/reservation": "예약 정보(판매 주문 목록)",
    "/clinic/notification": "알림",
    "/clinic/sms/template": "SMS 템플릿",
    "/clinic/sms/history": "SMS 내역",
    "/clinic/sms/create": "SMS 템플릿 생성",
    "/clinic/sms/template/update/[id]": "SMS 템플릿 수정",
    "/cart/tracking": "쇼핑몰 트래킹",
    "/reward": "리워드 관리",
    "/tracking": "쇼핑몰 트래킹",
  };

  useEffect(() => {
    if (!["ROLE_MASTER", "ROLE_CLINIC"].includes(session.user.role)) {
      return;
    }
    if (!router.pathname.includes("/clinic/")) return;
    const socket = new SockJS(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/ws`);

    const stompClient = new Client({
      webSocketFactory: () => socket,
      connectHeaders: {
        Authorization: `Bearer ${session.user.userToken}`,
      },
      reconnectDelay: 5000,
      onConnect: (frame) => {
        console.log("✅ WebSocket 연결 성공:", frame);

        const topics = [
          "/notifications/order",
          "/notifications/refund/confirm",
          "/notifications/refund/request",
        ];

        const subscriptions = topics.map((topic) =>
          stompClient.subscribe(topic, (message) => {
            dispatch(
              snackbarAction.setSnackbar({ type: "success", infinity: true, message: message.body })
            );
            dispatch(clinicAction.setIsNewNoti(true));
          })
        );

        // 구독 해제하는 함수 반환
        return () => {
          subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
      },
      onStompError: (frame) => {
        console.error("❌ STOMP 오류:", frame.body);
      },
    });

    stompClient.activate();
    console.log("🚀 stompClient.activate() 호출됨");

    return () => {
      console.log("🔌 WebSocket 연결 해제");
      stompClient.deactivate();
    };
  }, []);

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280,
          },
          width: {
            lg: "calc(100% - 280px)",
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ width: "100%", color: "black", fontSize: 20, fontWeight: 700 }}
          >
            {pathCode[router.pathname]}
            {router.pathname.includes("/clinic/") && (
              <Box
                sx={{
                  display: "flex",
                  fontSize: 19,
                  fontWeight: 500,
                  marginLeft: "10px",
                }}
              >
                <Box sx={{ width: "max-content", flexShrink: 0, lineHeight: "70px" }}>
                  [{storeInfo.store_name}]
                </Box>
                <Box ml={2}>
                  {storeInfo.id == 1 && (
                    <CustomSelect
                      value={admin_current_store_id}
                      setValue={(v) => dispatch(clinicAction.setAdminCurrentStoreId(v))}
                      options={[
                        { value: 2, label: "행동 케어클리닉" },
                        { value: 3, label: "아쿠아" },
                      ]}
                    />
                  )}
                </Box>
              </Box>
            )}
            <Box sx={{ marginLeft: "auto", marginRight: "20px" }}>
              {router.pathname.includes("/clinic/") && (
                <>
                  <ClinicNotification />
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={1}>
            <Chip
              avatar={<Avatar alt={session.user.name} src={session.user.image} />}
              label={session.user.name}
              variant="outlined"
            />
          </Stack>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};
