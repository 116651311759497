import { useEffect, useState } from "react";
import { Badge, IconButton, Popover, List, ListItem, ListItemText, Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useRouter } from "next/router";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { clinicAction } from "@store/clinic/clinicSlice";
import { useSession } from "next-auth/react";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { getTimeGap } from "@utils/helper";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";

export default function ClinicNotification() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const session = useSession();
  const userToken = session.data.user.userToken;
  const router = useRouter();
  const dispatch = useDispatch();

  const { data: getClinicNotificationListShort } = useSelector(
    (state) => state.clinic.getClinicNotificationListShort
  );
  const reservationSearchOption = useSelector((state) => state.clinic.reservationSearchOption);
  const isNewNoti = useSelector((state) => state.clinic.isNewNoti);

  // Popover 열기/닫기 핸들러
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  useEffect(() => {
    if (anchorEl) {
      dispatch(
        clinicAction.getClinicNotificationListShort({
          userToken,
        })
      );
    }
  }, [anchorEl]);

  const handleNotiClick = async (noti) => {
    if (noti.noti_json) {
      await new Promise((resolve) => {
        dispatch(
          clinicAction.setReservationSearchOption({
            key: "searchOption",
            value: "BOOK_SEARCH_ID",
          })
        );
        dispatch(
          clinicAction.setReservationSearchOption({
            key: "searchValue",
            value: noti.noti_json,
          })
        );
        setTimeout(resolve, 0); // 상태 업데이트 반영을 위해 0ms 딜레이
      });
      handleClose();

      if (router.pathname !== "/clinic/reservation") {
        router.push("/clinic/reservation");
      } else {
        let params = {
          userToken: userToken,
          dateType: reservationSearchOption.searchType,
          page: reservationSearchOption.page,
          size: reservationSearchOption.size,
          sort: reservationSearchOption.sort,
          searchType: reservationSearchOption.searchOption,
          searchValue: noti.noti_json,
        };

        dispatch(clinicAction.getReservationList(params));
      }
    }
  };
  return (
    <>
      {/* 알람 아이콘 버튼 */}
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon />
          {isNewNoti && (
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                right: "-7px",
                top: "-7px",
              }}
            >
              <rect width="22" height="20" rx="10" fill="#FFD317" />
              <path
                d="M8 14V6.66H8.96L11.63 11.29L12.41 12.8H12.46C12.41 12.06 12.34 11.24 12.34 10.48V6.66H13.22V14H12.27L9.6 9.37L8.81 7.86H8.77C8.81 8.6 8.88 9.38 8.88 10.15V14H8Z"
                fill="#221C15"
              />
            </svg>
          )}
        </Badge>
      </IconButton>

      {/* 알람 목록 Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ boxShadow: "2px 6px 12px rgb(0, 0, 0) !important" }}
      >
        {getClinicNotificationListShort && (
          <List sx={{ width: 450, maxHeight: 500, overflow: "auto", padding: "0 10px" }}>
            {getClinicNotificationListShort.content.length > 0 ? (
              getClinicNotificationListShort.content.map((noti, index) => (
                <NotiItem
                  onClick={() => handleNotiClick(noti)}
                  key={index}
                  sx={{ marginBottom: "10px" }}
                >
                  {noti.noti_type === "CONFIRM" ? (
                    <CheckCircleIcon
                      fontSize="medium"
                      sx={{ color: "#9ec27d", marginRight: "10px" }}
                    />
                  ) : noti.noti_type === "CANCEL" ? (
                    <CancelIcon fontSize="medium" sx={{ color: "#cd6060", marginRight: "10px" }} />
                  ) : (
                    <InfoIcon fontSize="medium" sx={{ color: "#3d78ba", marginRight: "10px" }} />
                  )}
                  <Box
                    sx={{
                      lineHeight: "1.5rem",
                    }}
                  >
                    <Box>{noti.noti_title}</Box>
                    <Box sx={{ fontSize: "13px", fontWeight: 400 }}>{noti.noti_message}</Box>
                    <Box sx={{ fontSize: "12px", fontWeight: 400 }}>
                      {getTimeGap(noti.created_at)}
                    </Box>
                  </Box>
                </NotiItem>
              ))
            ) : (
              <>
                <NotiItem>
                  <ListItemText sx={{ textAlign: "center" }} primary="알람이 없습니다." />
                </NotiItem>
              </>
            )}

            <Divider />
            <NotiItem sx={{ textAlign: "center" }}>
              <ListItemText
                onClick={() => {
                  setAnchorEl(null);
                  router.push("/clinic/notification");
                }}
              >
                <strong>모든 알람 보기</strong>
              </ListItemText>
            </NotiItem>
          </List>
        )}
      </Popover>
    </>
  );
}

const NotiItem = styled(ListItem)`
  border-radius: 8px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dedede;
`;

const NotiIcon = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid gray;
`;

const NotiText = styled.div`
  width: 350px;
  font-size: 12px;
  margin-left: auto;
`;
