import React from "react";
import styled from "styled-components";
import Select from "react-select";

export default function CustomSelect({
  title = "",
  value,
  setValue,
  options,
  style,
  essential = false,
  disabled = false,
  width = "100%",
  size = "medium", // small, medium, large
  type = "single",
}) {
  return (
    <Container width={width} style={style}>
      {type === "single" && (
        <>
          {title && (
            <div className="title">
              {title}
              {essential && (
                <span style={{ margin: "0 2px", color: "red", fontSize: "12px" }}>*</span>
              )}
            </div>
          )}
          <MySelect
            disabled={disabled}
            width={width}
            value={value}
            size={size}
            onChange={(e) => setValue(e.target.value)}
          >
            {options?.map((option, i) => (
              <MyOption key={i} value={option.value}>
                {option.label}
              </MyOption>
            ))}
          </MySelect>
        </>
      )}
      {type === "multi" && (
        <>
          <Select
            isMulti
            options={options}
            value={value || []}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => setValue(e)}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: ${(props) => props.width};
  margin: 15px 0;

  .title {
    width: 100%;
    font-size: 12px;
    color: rgb(50, 50, 50);
    margin: 5px 0;
  }
  select {
    // text-align-last: center;
  }
`;
const MySelect = styled.select`
  width: ${(props) => props.width};
  height: ${(props) =>
    props.size === "small" ? "35px" : props.size === "medium" ? "40px" : "50px"};
  padding: 0 30px 0 10px; /* 왼쪽 여백은 유지 */

  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: white;
  color: #333;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 16'%3E%3Cpath fill='%230056b3' d='M0 0h20L10 16z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 25px center; /* 오른쪽 여백 조정 */
  background-size: 8px; /* 화살표 크기 약간 키움 */
  padding-right: 40px !important; /* 텍스트가 화살표와 겹치지 않도록 패딩 추가 */

  &:hover {
    background-color: #e9f5ff;
  }

  &:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const MyOption = styled.option`
  font-size: 14px;
  background-color: #fff; /* 기본 흰색 배경 */
  color: #333; /* 기본 텍스트 색상 */
  padding: 10px;

  &:hover {
    background-color: #007bff; /* 커서 올렸을 때 파란 배경 */
    color: #fff; /* 텍스트 흰색 */
  }
`;
